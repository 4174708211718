.App {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.left {
  flex:1;
  display: flex;
  flex-direction: column;
  position: relative;
}

#map{
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.right {
  flex: 0 0 50%;
}


@media (max-width: 768px) {
  .App {
    flex-direction: column; /* Change to vertical layout on mobile */
  }

  .left,
  .right {
    flex: 1; /* Full width for left and right on mobile */
  }
}